<template>
  <div class="curriculum" v-loading="loading">
    <div class="content">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="0">当前课程</el-menu-item>
        <el-menu-item index="1">历史课程</el-menu-item>
      </el-menu>
      <el-card
        class="card-box"
        v-for="(i, _i) in list"
        :key="_i"
        @click="into(i)"
        v-show="i.showStatus == activeIndex"
      >
        <div class="pic">
          <img :src="'https://tecsf.marsuntec.com' + i.coverFile" alt="" />
        </div>
        <div class="detail">
          <div class="title">{{ i.title }}</div>
          <div class="progress">学习进度：{{ i.progress }}%</div>
          <div class="date">学习有效期：截止{{ i.endTime }}</div>
        </div>
      </el-card>
      <div class="empty" v-if="!list.length && !loading">您暂无课程</div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "curriculum",
  data() {
    return {
      activeIndex: 0,
      loading: true,
      totalList: [],
      list: [],
    };
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
      this.list = this.totalList.filter(
        (el) => el.showStatus == this.activeIndex
      );
    },
    initData() {
      this.$https.get("/api/my/course/list").then((res) => {
        if (res.success) {
          const data = res.data;
          this.totalList = data.map((el) => {
            return {
              ...el,
              endTime: moment(el.endTime).format("YYYY-MM-DD"),
              startTime: moment(el.startTime).format("YYYY-MM-DD"),
              status: juedeStatus(el),
              showStatus: juedeStatus(el) < 2 ? 0 : 1,
              progress: Object.keys(el.coursewareDone).length
                ? (
                    (Object.keys(el.coursewareDone).length /
                      el.totalCoursewares) *
                    100
                  ).toFixed(2)
                : 0,
            };
          });
          this.list = this.totalList.filter(
            (el) => el.showStatus == this.activeIndex
          );
        }
        this.loading = false;

        // 判断课程状态，0未开始，1已经开始，2过期
        function juedeStatus(item) {
          const currentTime = moment().valueOf();
          let status;
          if (currentTime < item.startTime) {
            status = 0;
          }
          if (currentTime >= item.startTime && currentTime <= item.endTime) {
            status = 1;
          }
          if (currentTime > item.endTime) {
            status = 2;
          }
          return status;
        }
      });
    },
    into(item) {
      if (item.status != 2) {
        window.open("/curriculumDetails?id=" + item.id);
      } else {
        this.$message.error("课程已过期");
      }
      // this.$router.push({
      //   path: "/curriculumDetails",
      //   query: {
      //     id: item.id,
      //   },
      // });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style lang="less">
.curriculum {
  position: relative;
  text-align: center;
  padding: 2% 0;
  .content {
    display: inline-block;
    width: 90%;
    text-align: left;
    .card-box {
      width: 23%;
      margin: 1%;
      display: inline-block;
      text-align: left;
      border-radius: 5px;
      cursor: pointer;
      .pic {
        height: 200px;
        img {
          width: 100%;
          height: 200px;
          object-fit: contain;
        }
      }
      .detail {
        padding: 10px;
        line-height: 1.5;
        .title {
          font-size: 14px;
          font-weight: bold;
          color: #677897;
        }
        .progress {
          font-size: 12px;
          color: #b0b9ca;
        }
        .date {
          font-size: 14px;
          color: #67c23a;
        }
      }
    }
  }
}
</style>
